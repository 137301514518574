.accordion .accordion-item.active .panel {
    max-height: 100%;
}

.accordion .accordion-item .title {
    font-size: 18px;
}

.accordion .accordion-item.active .title {
    background-color: #ccc;
    font-weight: bold;
}